import { Modal } from "@kamae-apps/shared/Component/NewModal/Modal";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { DiscoveryButton } from "./DiscoveryButton";

export const DiscoveryPill: FC = () => {
  const { t } = useTranslation("discoveryLicence");
  const [discoveryModalDisplay, setDiscoveryModalDisplay] = useState(false);

  return (
    <>
      <DiscoveryModalPill
        discoveryModalDisplay={discoveryModalDisplay}
        setDiscoveryModalDisplay={setDiscoveryModalDisplay}
      />
      <div
        onClick={() => {
          setDiscoveryModalDisplay(true);
        }}
        className="cursor-pointer rounded-full bg-purple-100 px-1 py-2 md:px-5"
      >
        <span className="whitespace-nowrap text-center text-purple-600">
          {t("discoveryLicence:licenceName")}
        </span>
      </div>
    </>
  );
};

type DiscoveryModalPillProps = {
  discoveryModalDisplay: boolean;
  setDiscoveryModalDisplay: (v: boolean) => void;
};

const DiscoveryModalPill: FC<DiscoveryModalPillProps> = ({
  discoveryModalDisplay,
  setDiscoveryModalDisplay,
}) => {
  const { t } = useTranslation("discoveryLicence");

  return (
    <Modal
      title={t("discoveryLicence:licenceName")}
      display={discoveryModalDisplay}
      setDisplay={setDiscoveryModalDisplay}
      width={"md:w-2/5"}
    >
      <div className="[&>p]:font-gilroy-medium p-8 text-black/60">
        <p>{t("discoveryLicence:yourCompanyEnjoysDiscover")}</p>
        <p>{t("discoveryLicence:withFullVersionYouCanAccess")}</p>
        <ul className="[&>li]:font-gilroy-medium mt-5 pl-5">
          <li>• {t("discoveryLicence:fullTrainings")}</li>
          <li>• {t("discoveryLicence:monthlyChallenges")}</li>
          <li>• {t("discoveryLicence:unlimitedPhishingAttacks")}</li>
          <li>• {t("discoveryLicence:andMore")}</li>
        </ul>
      </div>
      <DiscoveryButton className="m-auto" />
    </Modal>
  );
};
