import { FC } from "react";
import { ConditionalRender } from "@kamae-apps/shared/Component/ConditionalRender/ConditionalRender";
import clsx from "clsx";
import { XCircle } from "react-feather";

type ModalProps = {
  title: string;
  display: boolean;
  setDisplay: (v: boolean) => void;
  children?: any;
  width?: string;
};

export const Modal: FC<ModalProps> = ({
  title,
  display,
  setDisplay,
  children,
  width,
}) => {
  const close = () => {
    setDisplay(false);
  };

  return (
    <ConditionalRender condition={display}>
      <div
        className={clsx(
          "fixed left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-gray-400 bg-opacity-40 backdrop-blur-sm",
          !display && "hidden"
        )}
        onClick={close}
      >
        <div
          className={clsx(
            "relative flex flex-col items-center gap-5 rounded-xl bg-white px-6 py-3 drop-shadow",
            width
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <XCircle
            className={
              "no-fill absolute right-5 top-5 cursor-pointer hover:drop-shadow"
            }
            onClick={close}
          />
          <div
            className={
              "border-b-1 flex w-full items-center justify-center border-gray-200 py-3"
            }
          >
            <p>{title}</p>
          </div>
          <div className={"flex w-full flex-col gap-6"}>{children}</div>
        </div>
      </div>
    </ConditionalRender>
  );
};
