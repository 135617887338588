import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SideBar } from "../SideBar/SideBar";
import { sha1, shouldThePathBeMemoized } from "../../utils";
import Error401 from "../../Error/Error401/Error401";
import LicenseExpired from "../../Error/LicenseExpired/LicenseExpired";
import Error404 from "../../Error/Error404";
import { Header } from "../Header/Header";
import Loading from "@kamae-apps/shared/Component/Loading/Loading";
import { useCryptr } from "@cryptr/cryptr-react";
import posthog from "posthog-js";
import {
  apiRequest,
  basicCompare,
  isLargeScreen,
} from "@kamae-apps/shared/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { NewBeltModal } from "./NewBeltModal";
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt";
import { ToastContainerContext } from "@kamae-apps/shared/Component/Toast/Context";
import { ToastContainer } from "@kamae-apps/shared/Component/Toast/Toast";
import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany";
import TUser from "@kamae-apps/shared/Types/TUser";
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope";
import { useFeatureFlags } from "../../hooks/posthog/useFeatureFlags";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useUser from "../../hooks/useUser";
import { UserConfigStateProvider } from "@kamae-apps/shared/Hooks/userConfig/Context";
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage";
import { APIError } from "@kamae-apps/shared/Types/API";
import useReload from "@kamae-apps/shared/Hooks/useReload";
import useApiStatus from "@kamae-apps/shared/Hooks/useApiStatus/useApiStatus";
import MaintenancePage from "../../Error/MaintenancePage/MaintenancePage";
import { useTranslation } from "react-i18next";

import { faro } from "@grafana/faro-web-sdk";
import useIntercom from "../../hooks/useIntercom";
import { useWindowSize } from "@kamae-apps/shared/Hooks/useWindowSize";
import PhishingModal from "./PhishingModal";
import BotPage from "../BotPage/BotPage";
import config from "../../variable";
import LearningPage from "../LearningPage/LearningPage";
import { ConditionalRender } from "@kamae-apps/shared/Component/ConditionalRender/ConditionalRender";
import CompanyDoesNotExist from "../../Error/CompanyDoesNotExist/CompanyDoesNotExist";
import UserDoesNotExist from "../../Error/UserDoesNotExist/UserDoesNotExist";

const Home = React.lazy(() => import("../Home/Home"));
const Profile = React.lazy(() => import("../Profile/Profile"));
const Trainings = React.lazy(() => import("../TrainingsPage/TrainingsPage"));
const PhishingAdmin = React.lazy(
  () => import("../Phishing/components/PhishingAdmin")
);
const LibraryPage = React.lazy(() => import("../LibraryPage/LibraryPage"));
const Supervision = React.lazy(() => import("../Supervision/Supervision"));

const TeamSupervisionPage = React.lazy(
  () =>
    import("../Pages/TeamSupervision/TeamSupervisionPage/TeamSupervisionPage")
);

const TeamUserListPage = React.lazy(
  () => import("../Pages/TeamSupervision/TeamUserListPage/TeamUserListPage")
);

const CompanyUsers = React.lazy(
  () => import("../OrganizationPage/Containers/Users/Users")
);
const Teams = React.lazy(
  () => import("../OrganizationPage/Containers/Teams/Teams")
);
const Tools = React.lazy(
  () => import("../OrganizationPage/Containers/Tools/Tools")
);

const Login = React.lazy(() => import("../LoginPage/LoginPage"));
const PhishingUser = React.lazy(
  () => import("../Phishing/components/PhishingUser")
);
const CampaignAdminDetail = React.lazy(
  () => import("../Phishing/CampaignAdminDetail")
);
const CampaignUserDetail = React.lazy(
  () => import("../Phishing/CampaignUserDetail")
);
const Organization = React.lazy(
  () => import("../OrganizationPage/OrganizationPage")
);
const Welcome = React.lazy(() => import("../WelcomePage/WelcomePage"));
const Training = React.lazy(() => import("../TrainingPage/TrainingPage"));
const ChallengePage = React.lazy(
  () => import("../ChallengePage/ChallengePage")
);
const PhishingAdminPage = React.lazy(
  () => import("../NewPhishing/PhishingAdminPage/PhishingAdminPage")
);
const CreateCampaignPage = React.lazy(
  () => import("../NewPhishing/CreateCampaignPage/CreateCampaignPage")
);
const AutomationPage = React.lazy(
  () => import("../NewPhishing/AutomationPage/AutomationPage")
);
const CampaignAdminDetailPage = React.lazy(
  () => import("../NewPhishing/CampaignAdminDetailPage/CampaignAdminDetailPage")
);

const emptyCompany: TCompany = {
  name: "",
  id: -1,
  level: -1,
  progress: -1,
  creation: new Date(),
  logo: "",
  status: -1,
  automated_phishing_activated: false,
  phishing_enabled: false,
  sso_enabled: false,
  lms_enabled: false,
  defaultLanguageId: DefaultLanguage.FR,
  dir_sync_enabled: false,
};

export default function App() {
  const size = useWindowSize();
  const [jwt, setJwt] = useState<string | null>(null);

  const [loaded, setLoaded] = useState<boolean>(false);
  const { reload: elearningReload, doReload: doElearningReload } = useReload();
  const [displayNewBelt, setDisplayNewBelt] = useState(false);

  const ref = useRef<ToastContainer>(null);
  const { user, setUserState } = useUser();
  const [searchParams] = useSearchParams();

  const featureFlag = useFeatureFlags();

  const cryptr = useCryptr();
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const apiStatus = useApiStatus();

  const { t, i18n } = useTranslation("app");

  const persistLanguage = useCallback(
    (lng?: string) => {
      if (!i18n.isInitialized || !lng) {
        return;
      }

      apiRequest("/user/lang", {
        method: "POST",
        body: {
          code: lng,
        },
        headers: {
          "X-Targeted-Language": lng,
        },
      });
    },
    [i18n.isInitialized]
  );

  // Need to be in a useEffect, if not, it will re-subscribe to languageChanged event on each render
  useEffect(() => {
    i18n.on("languageChanged", persistLanguage);

    return () => {
      i18n.off("languageChanged", persistLanguage);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [persistLanguage]);

  const {
    instantiateIntercom,
    updateIntercomWithUserInformation,
    shutdownIntercom,
  } = useIntercom();

  useEffect(() => {
    instantiateIntercom();
  }, [instantiateIntercom]);

  useEffect(() => {
    if (loaded && typeof user.scope !== "undefined") {
      if (scopeHas(user.scope, Scope.COMPANY_ADMIN)) {
        updateIntercomWithUserInformation(!isLargeScreen(size));
      } else {
        shutdownIntercom();
      }
    }
  }, [
    shutdownIntercom,
    updateIntercomWithUserInformation,
    loaded,
    user.scope,
    size,
  ]);

  const getUser = useCallback(async () => {
    if (!jwt) {
      return;
    }

    try {
      const data = await apiRequest<TUser>("/user");

      if (!data) {
        return;
      }

      const oldBelt = localStorage.getItem("belt");

      if (oldBelt !== null) {
        if (data.level > parseInt(oldBelt)) {
          setDisplayNewBelt(true);
          const level = data.level;
          sha1(data.email).then((identifier) => {
            posthog.identify(identifier);
            posthog.capture("earned new belt", {
              level: level,
            });
          });
        }
      }

      localStorage.setItem("belt", data.level.toString());

      if (user === null || !basicCompare(data, user)) {
        setUserState(data);
      }
    } catch (err) {
      const errorsToCatch = [401, 403];
      if (errorsToCatch.includes((err as APIError).code)) {
        const redirectTo = () => {
          switch ((err as APIError).code) {
            case 401:
              return "/unauthorized";
            case 403:
              return "/forbidden";
            default:
              return "/";
          }
        };

        if (cryptr.isAuthenticated()) {
          await cryptr.logOut(() => navigate(redirectTo()), undefined, false);
        } else if (auth0.isAuthenticated) {
          auth0.logout({ returnTo: window.location.origin + redirectTo() });
        }
        localStorage.clear();
      }
    }
  }, [jwt, user, setUserState, auth0, cryptr, navigate, i18n.changeLanguage]);

  const RequestAccessTokenCryptr = useCallback(async () => {
    const token = cryptr.getCurrentAccessToken();
    setJwt(token ?? "");
    localStorage.setItem("jwt", token ?? "");
    await getUser();
  }, [cryptr, getUser]);

  const RequestAccessTokenAuth0 = useCallback(async () => {
    const token = await auth0.getAccessTokenSilently();
    setJwt(token);
    localStorage.setItem("jwt", token);
    await getUser();
  }, [auth0, getUser]);

  useEffect(() => {
    if (
      !cryptr.isAuthenticated() &&
      !cryptr.isLoading &&
      !auth0.isLoading &&
      !auth0.isAuthenticated &&
      ![
        "/unauthorized",
        "/forbidden",
        "/company-not-found",
        "/user-not-found",
        "/bot",
        "/learning-page",
      ].includes(location.pathname)
    ) {
      const state = searchParams.get("state");
      const code = searchParams.get("code");
      if (state === null) {
        // Memoized current url for redirection after login
        if (shouldThePathBeMemoized(location.pathname)) {
          localStorage.setItem("redirectTo", location.pathname);
        }
        navigate("/login" + document.location.search);
      } else {
        window.location.replace(
          "/organization?vantaState=" + state + "&code=" + code
        );
      }
    } else if (cryptr.isAuthenticated()) {
      RequestAccessTokenCryptr();
    } else if (auth0.isAuthenticated) {
      RequestAccessTokenAuth0();
    }
  }, [
    cryptr,
    auth0,
    navigate,
    RequestAccessTokenAuth0,
    RequestAccessTokenCryptr,
    searchParams,
    location.pathname,
  ]);

  useEffect(() => {
    if (typeof user.scope !== "undefined") {
      // if user fully loaded
      sha1(user.email).then((identifier) => {
        const scopeEnum = Scope;
        if (user.company.total_user) {
          posthog.register({
            company_total_users: user.company.total_user,
          });
        }
        posthog.identify(identifier, {
          company_name: user.company.name,
          team_name: user.team.name,
          user_role: scopeEnum[user.scope ?? scopeEnum.USER],
        });
        posthog.group("Company", "id:" + user.company.id, {
          name: user.company.name,
        });
      });
    }
  }, [user]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (loaded && config.env !== "development") {
      faro.api.setUser({
        email: user.email,
        id: user.id.toString(),
        attributes: {
          company_id: user.company.id.toString(),
          company_name: user.company.name,
        },
      });
    }
  }, [loaded]);
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    // IF user fully loaded AND featureFlag fully loaded AND we are not in a LoginPage popup
    if (
      typeof user.scope !== "undefined" &&
      featureFlag.length > 0 &&
      (window.opener === null || window.name !== "LoginPopup")
    ) {
      if (!user.avatar) {
        navigate("/welcome");
      } else {
        if (location.pathname === "/") {
          // Redirect the user
          const redirection = localStorage.getItem("redirectTo");
          if (redirection !== null) {
            localStorage.removeItem("redirectTo");
            navigate(redirection ?? "");
          } else {
            if (scopeHas(user.scope, Scope.COMPANY_ADMIN)) {
              if (user.company.onboarding_completed) {
                navigate("/supervision");
              } else {
                navigate("/organization");
              }
            } else {
              navigate("/home");
            }
          }
        }
      }
      setLoaded(true);
    }
  }, [
    featureFlag.length,
    navigate,
    featureFlag,
    location.pathname,
    user?.company.onboarding_completed,
    user?.scope,
    user?.avatar,
    user?.company.id,
    user?.company.name,
    user?.company.total_user,
    user?.email,
    user?.first_name,
    user?.id,
    user?.last_name,
    user?.intercom_hash,
  ]);

  useEffect(() => {
    posthog.capture("$pageview");
    window.scrollTo({ top: 0 });
  }, [location]);

  useEffect(() => {
    getUser();
  }, [jwt, location.pathname, getUser]);

  return (
    ((apiStatus.code === 503 || apiStatus.code === -1) && (
      <MaintenancePage />
    )) || (
      <Routes>
        <Route
          path={"/login"}
          element={
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          }
        />
        {typeof user.avatar === "undefined" && (
          <Route
            path={"/welcome"}
            element={
              <Suspense fallback={<Loading />}>
                <UserConfigStateProvider loaded={loaded}>
                  <ToastContainerContext.Provider value={ref}>
                    <ToastContainer ref={ref} />
                    <Welcome />
                  </ToastContainerContext.Provider>
                </UserConfigStateProvider>
              </Suspense>
            }
          />
        )}
        {user.company.lms_enabled && user.is_lms_user && (
          <Route
            path={"/elearning/:elearningId"}
            element={
              <Suspense fallback={<Loading />}>
                <Training loaded={loaded} />
              </Suspense>
            }
          />
        )}
        <Route
          element={
            <UserConfigStateProvider loaded={loaded}>
              <div
                className={
                  "flex min-h-screen flex-col gap-4 pb-12 md:h-screen md:min-h-max md:flex-row md:pb-0"
                }
              >
                <NewBeltModal
                  firstName={user?.first_name ?? ""}
                  display={displayNewBelt}
                  setDisplay={setDisplayNewBelt}
                  beltColor={getBeltColor(user?.level ?? 0)}
                />
                <PhishingModal
                  user={user}
                  loaded={loaded}
                />
                {!loaded && <Loading />}
                <div className={"w-full md:w-[230px]"}>
                  <div
                    className={
                      "left-0 top-0 flex h-full w-full justify-center md:w-[230px]"
                    }
                  >
                    <SideBar
                      t={t}
                      loaded={loaded}
                    />
                  </div>
                </div>
                <ToastContainerContext.Provider value={ref}>
                  <div
                    className={
                      "custom-scrollbar md:w-main flex w-full grow flex-col overflow-y-auto overflow-x-hidden scroll-smooth p-1 md:p-0 md:pr-2"
                    }
                  >
                    <Header
                      t={t}
                      loaded={loaded}
                    />
                    {loaded && <Outlet />}
                    <ToastContainer ref={ref} />
                  </div>
                </ToastContainerContext.Provider>
              </div>
            </UserConfigStateProvider>
          }
        >
          <Route
            path={"/home"}
            element={
              <Suspense fallback={<Loading />}>
                <Home
                  loaded={loaded}
                  reload={elearningReload}
                />
              </Suspense>
            }
          />
          <Route
            path={"/profile"}
            element={
              <Suspense fallback={<Loading />}>
                <Profile />
              </Suspense>
            }
          />
          {user.company.phishing_enabled && (
            <Route path={"/phishing"}>
              <Route
                path={""}
                element={
                  <Suspense fallback={<Loading />}>
                    {scopeHas(user?.scope, Scope.COMPANY_ADMIN) ? (
                      <>
                        <ConditionalRender
                          condition={featureFlag.includes("phishing-automated")}
                        >
                          <PhishingAdminPage />
                        </ConditionalRender>
                        <ConditionalRender
                          condition={
                            !featureFlag.includes("phishing-automated")
                          }
                        >
                          <PhishingAdmin
                            userCompanyId={user?.company.id}
                            mail={user?.email ?? ""}
                          />
                        </ConditionalRender>
                      </>
                    ) : (
                      <PhishingUser />
                    )}
                  </Suspense>
                }
              />
              {scopeHas(user?.scope, Scope.COMPANY_ADMIN) && (
                <Route
                  path={"create"}
                  element={
                    <Suspense fallback={<Loading />}>
                      <CreateCampaignPage />
                    </Suspense>
                  }
                />
              )}
              {scopeHas(user?.scope, Scope.COMPANY_ADMIN) &&
                featureFlag.includes("phishing-automated") && (
                  <Route
                    path={"automation"}
                    element={
                      <Suspense fallback={<Loading />}>
                        <AutomationPage />
                      </Suspense>
                    }
                  />
                )}
              <Route
                path={":id"}
                element={
                  <Suspense fallback={<Loading />}>
                    {scopeHas(user?.scope, Scope.COMPANY_ADMIN) ? (
                      <>
                        <ConditionalRender
                          condition={featureFlag.includes("phishing-automated")}
                        >
                          <CampaignAdminDetailPage />
                        </ConditionalRender>
                        <ConditionalRender
                          condition={
                            !featureFlag.includes("phishing-automated")
                          }
                        >
                          <CampaignAdminDetail />
                        </ConditionalRender>
                      </>
                    ) : (
                      <CampaignUserDetail />
                    )}
                  </Suspense>
                }
              />
            </Route>
          )}
          <Route
            path={"/reflexes"}
            element={
              <Suspense fallback={<Loading />}>
                <LibraryPage />
              </Suspense>
            }
          />
          {scopeHas(user?.scope, Scope.COMPANY_ADMIN) && (
            <>
              <Route path={"/supervision"}>
                <Route
                  path={""}
                  element={
                    <Suspense fallback={<Loading />}>
                      <Supervision />
                    </Suspense>
                  }
                />
              </Route>
              <Route path={"/organization"}>
                <Route
                  path={""}
                  element={
                    <Suspense fallback={<Loading />}>
                      <Organization />
                    </Suspense>
                  }
                />
                <Route
                  path={"users"}
                  element={
                    <Suspense fallback={<Loading />}>
                      <CompanyUsers company={user?.company ?? emptyCompany} />
                    </Suspense>
                  }
                />
                <Route
                  path={"teams"}
                  element={
                    <Suspense fallback={<Loading />}>
                      <Teams
                        company={user?.company ?? emptyCompany}
                        getUser={getUser}
                      />
                    </Suspense>
                  }
                />
                {featureFlag.includes("phishing-automated") && (
                  <Route
                    path={"tools"}
                    element={
                      <Suspense fallback={<Loading />}>
                        <Tools />
                      </Suspense>
                    }
                  />
                )}
              </Route>
            </>
          )}
          {featureFlag.includes("team-leaders") &&
            user.id === user.team.leader?.id && (
              <Route path={"/team"}>
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loading />}>
                      <TeamSupervisionPage />
                    </Suspense>
                  }
                />
                <Route
                  path={"users"}
                  element={<TeamUserListPage team={user.team} />}
                />
              </Route>
            )}
          <Route
            path={"/elearning"}
            element={
              <Suspense fallback={<Loading />}>
                <Trainings
                  doReload={doElearningReload}
                  reload={elearningReload}
                />
              </Suspense>
            }
          />
          {featureFlag.includes("new-challenge") && (
            <Route
              path={"/challenge"}
              element={<ChallengePage />}
            />
          )}
          <Route
            path={"*"}
            element={<Error404 />}
          />
        </Route>
        <Route
          path={"/unauthorized"}
          element={<Error401 />}
        />
        <Route
          path={"/forbidden"}
          element={<LicenseExpired />}
        />
        <Route
          path={"/company-not-found"}
          element={<CompanyDoesNotExist />}
        />
        <Route
          path={"/user-not-found"}
          element={<UserDoesNotExist />}
        />
        <Route
          path={"/bot"}
          element={<BotPage />}
        />
        <Route
          path={"/learning-page"}
          element={<LearningPage />}
        />
      </Routes>
    )
  );
}
